import { employeeReviewActionApi } from '@/api/employee-review-action/EmployeeReviewAction.api';
import { CreateActionMutation, EmployeeReviewAction, UpdateActionMutation } from '@/domain/employee-review-action/employee-review-action.model';

export const getEmployeeReviewActions = (employeeReviewId: number): Promise<EmployeeReviewAction[]> => {
    return employeeReviewActionApi.getEmployeeReviewActions(employeeReviewId);
};

export const updateEmployeeReviewAction = (employeeReviewActionId: number, updatedAction: UpdateActionMutation): Promise<EmployeeReviewAction> => {
    return employeeReviewActionApi.updateEmployeeReviewAction(employeeReviewActionId, updatedAction);
};

export const deleteEmployeeReviewAction = (employeeReviewActionId: number): Promise<void> => {
    return employeeReviewActionApi.deleteEmployeeReviewAction(employeeReviewActionId);
};

export const createEmployeeReviewAction = (employeeReviewId: number, createAction: CreateActionMutation): Promise<EmployeeReviewAction> => {
    return employeeReviewActionApi.createEmployeeReviewAction(employeeReviewId, createAction);
};

export const getEmployeeReviewActionSummary = (employeeReviewId: number): Promise<EmployeeReviewAction[]> => {
    return employeeReviewActionApi.getEmployeeReviewActionSummary(employeeReviewId);
};

export const getSelfSummary = (employeeReviewId: number): Promise<EmployeeReviewAction[]> => {
    return employeeReviewActionApi.getSelfSummary(employeeReviewId);
};

export const getManagerSummary = (employeeReviewId: number): Promise<EmployeeReviewAction[]> => {
    return employeeReviewActionApi.getManagerSummary(employeeReviewId);
};
