import { reviewTemplateAPI } from '@/api/review-template/ReviewTemplate.api';
import { ReviewTemplate, ReviewTemplateItemType, ReviewTemplateMutation, SearchReviewTemplate } from '@/domain/review-template/ReviewTemplate.model';
import { ContributorType } from '@/domain/review/Review.model';
import { ReviewTemplateFormType } from '@/page/setting/review/template/ReviewTemplateFormPage.schema';

export const getReviewTemplate = (reviewTemplateId: number): Promise<ReviewTemplate> => {
    return reviewTemplateAPI.getReviewTemplate(reviewTemplateId);
};

export const updateReviewTemplate = (reviewTemplateId: number, request: ReviewTemplateMutation): Promise<ReviewTemplate> => {
    return reviewTemplateAPI.updateReviewTemplate(reviewTemplateId, request);
};

export const deleteReviewTemplate = (reviewTemplateId: number): Promise<void> => {
    return reviewTemplateAPI.deleteReviewTemplate(reviewTemplateId);
};

export const createReviewTemplate = (request: ReviewTemplateMutation): Promise<ReviewTemplate> => {
    return reviewTemplateAPI.createReviewTemplate(request);
};

export const searchReviewTemplates = (request: SearchReviewTemplate = {}): Promise<ReviewTemplate[]> => {
    return reviewTemplateAPI.searchReviewTemplates(request);
};

export const cloneReviewTemplate = (reviewTemplateId: number): Promise<ReviewTemplate> => {
    return reviewTemplateAPI.cloneReviewTemplate(reviewTemplateId);
};

export const isReviewTemplateItemQuestion = (type: ReviewTemplateItemType): type is 'QUESTION' => {
    return type === 'QUESTION';
};

export const isReviewTemplateItemPrivateEmployeeQuestion = (type: ReviewTemplateItemType): type is 'PRIVATE_EMPLOYEE_QUESTION' => {
    return type === 'PRIVATE_EMPLOYEE_QUESTION';
};

export const isReviewTemplateItemPrivateManagerQuestion = (type: ReviewTemplateItemType): type is 'PRIVATE_MANAGER_QUESTION' => {
    return type === 'PRIVATE_MANAGER_QUESTION';
};

export const isReviewTemplateItemSection = (type: ReviewTemplateItemType): type is 'SECTION' => {
    return type === 'SECTION';
};

export const isReviewTemplateItemSkill = (type: ReviewTemplateItemType): type is 'SKILL' => {
    return type === 'SKILL';
};

export const isReviewTemplateItemObjectives = (type: ReviewTemplateItemType): type is 'REVIEW_OBJECTIVES' => {
    return type === 'REVIEW_OBJECTIVES';
};

export const isReviewTemplateItemObjectivesAction = (type: ReviewTemplateItemType): type is 'OBJECTIVE_ACTIONS' => {
    return type === 'OBJECTIVE_ACTIONS';
};

export const isReviewTemplateItemSkills = (type: ReviewTemplateItemType): type is 'SKILLS' => {
    return type === 'SKILLS';
};

export const getAvailableContributorTypes = (reviewTemplateFormValues: ReviewTemplateFormType): ContributorType[] => {
    return [
        reviewTemplateFormValues.selfReview && 'SELF',
        reviewTemplateFormValues.managerPreparation && 'MANAGER',
        reviewTemplateFormValues.peerFeedback && 'PEER',
        reviewTemplateFormValues.upwardFeedback && 'UPWARD',
    ].filter(Boolean) as ContributorType[];
};
