import { createRequiredParamsQueryHook } from '@/page/Query.type';
import {
    getEmployeeReviewActions,
    getEmployeeReviewActionSummary,
    getManagerSummary,
    getSelfSummary,
} from '@/domain/employee-review-action/employee-review-action.service';

export const useGetEmployeeReviewActions = createRequiredParamsQueryHook('employeeReviewActions', getEmployeeReviewActions);

export const useGetEmployeeReviewActionSummary = createRequiredParamsQueryHook('employeeReviewActionSummary', getEmployeeReviewActionSummary);
export const useGetEmployeeReviewActionSelfSummary = createRequiredParamsQueryHook('employeeReviewActionSelfSummary', getSelfSummary);
export const useGetEmployeeReviewActionManagerSummary = createRequiredParamsQueryHook('employeeReviewActionManagerSummary', getManagerSummary);
