import { StateHandler } from '@/components/state-handler/StateHandler';
import { useGetObjectiveSetting } from '@/hooks/objective-setting/ObjectiveSetting.hook';
import { Box, Button, Paper, Stack, StackProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EmployeeAvatar } from '@/components/employee-avatar/EmployeeAvatar';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { Objective, ObjectiveCreateMutation, ObjectiveUpdateMutation } from '@/domain/objective/Objective.model';
import { showSnackbar } from '@/utils/snackbar.util';
import { handleError } from '@/utils/api.util';
import {
    createEmployeeReviewAction,
    deleteEmployeeReviewAction,
    updateEmployeeReviewAction,
} from '@/domain/employee-review-action/employee-review-action.service';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { EmployeeReviewAction } from '@/domain/employee-review-action/employee-review-action.model';
import { AddIndividualObjectiveDialog, IndividualObjectiveDialogFormType } from '@/page/objective/add-individual-objective-dialog/AddIndividualObjectiveDialog';
import { getLabelTranslation } from '@/utils/language.util';
import { Target01Icon as ObjectivesIcon } from 'hugeicons-react';
import { getAppConfig } from '@/config/config';
import { formatDate } from 'date-fns';
import i18next from 'i18next';

type EmployeeReviewFeedbackObjectiveActionsProps = {
    employeeId: number;
    disabled: boolean;
    refetchActions: () => void;
    employeeReview: EmployeeReview;
    employeeReviewActions: EmployeeReviewAction[];
} & StackProps;

type ObjectiveActionDialogState = {
    open: boolean;
    activeObjectiveAction?: EmployeeReviewAction;
};

export const EmployeeReviewFeedbackObjectiveActions: FC<EmployeeReviewFeedbackObjectiveActionsProps> = ({
    employeeId,
    disabled,
    refetchActions,
    employeeReviewActions,
    employeeReview,
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [objectiveActionDialogState, setObjectiveActionDialogState] = useState<ObjectiveActionDialogState>({
        open: false,
        activeObjectiveAction: undefined,
    });

    const {
        data: objectiveSetting,
        isLoading: isLoadingObjectiveSetting,
        isError: isErrorObjectiveSetting,
        error: errorObjectiveSetting,
    } = useGetObjectiveSetting();

    const emptyState = (
        <Stack p={2}>
            <Typography variant={'body1'}>{t('reviews.write_feedback.objective_actions_empty_state')}</Typography>
        </Stack>
    );

    const resetObjectiveActionDialogState = () => {
        setObjectiveActionDialogState({
            open: false,
            activeObjectiveAction: undefined,
        });
    };
    const onSave = async (formValues: IndividualObjectiveDialogFormType, employeeReviewId: number, objectiveId?: number) => {
        if (objectiveId) {
            const objectiveMutation = {
                ...formValues,
                assigneeId: formValues.assigneeIds[0],
                parentId: formValues.parentId ?? undefined,
            };
            await onUpdateObjectiveAction(objectiveMutation, objectiveId);
        } else {
            const objectiveMutation = {
                ...formValues,
                parentId: formValues.parentId ?? undefined,
            };
            await onCreateObjectiveAction(objectiveMutation, employeeReviewId);
        }
    };

    const onCreateObjectiveAction = async (objectiveRequest: ObjectiveCreateMutation, employeeReviewId: number) => {
        const createAction = {
            objectiveCreateMutation: objectiveRequest,
        };
        try {
            await createEmployeeReviewAction(employeeReviewId, createAction);
            showSnackbar(t('reviews.write_feedback.objective_action_created'), 'success');
            refetchActions();
            resetObjectiveActionDialogState();
        } catch (error) {
            handleError(error);
        }
    };

    const onUpdateObjectiveAction = async (objectiveRequest: ObjectiveUpdateMutation, employeeReviewActionId: number) => {
        const updatedAction = {
            objectiveUpdateMutation: objectiveRequest,
        };

        try {
            await updateEmployeeReviewAction(employeeReviewActionId, updatedAction);
            showSnackbar(t('reviews.write_feedback.objective_action_updated'), 'success');
            refetchActions();
            resetObjectiveActionDialogState();
        } catch (error) {
            handleError(error);
        }
    };

    const onDeleteObjective = async (employeeReviewActionId: number) => {
        try {
            await deleteEmployeeReviewAction(employeeReviewActionId);
            showSnackbar(t('reviews.write_feedback.objective_action_deleted'), 'success');
            refetchActions();
            resetObjectiveActionDialogState();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <Stack gap={2} component={Paper} p={2}>
            <StateHandler
                isLoading={isLoadingObjectiveSetting}
                isError={isErrorObjectiveSetting}
                error={errorObjectiveSetting}
                emptyStateComponent={emptyState}
            >
                {objectiveSetting && (
                    <Stack gap={1}>
                        <Typography variant='h2'>{t('reviews.write_feedback.create_objective_action_title')}</Typography>

                        {!!employeeReviewActions.length && (
                            <TableContainer>
                                <Table
                                    sx={{
                                        borderSpacing: `0 ${theme.spacing(2)}`,
                                        borderCollapse: 'separate',
                                        // todo: find a better way to do this
                                        margin: `-${theme.spacing(2)} 0 0 0`,
                                    }}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={'70%'}>
                                                <Typography variant='body1bold'>{t('reviews.write_feedback.create_objective_name_header_title')}</Typography>
                                            </TableCell>
                                            <TableCell sx={{ textAlign: 'center' }}>
                                                <Typography variant='body1bold'>
                                                    {t('reviews.write_feedback.create_objective_action_create_by_header_title')}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant='body1bold'>
                                                    {t('reviews.write_feedback.create_objective_action_due_date_header_title')}
                                                </Typography>
                                            </TableCell>
                                            {!disabled && <TableCell width={'16px'} />}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {employeeReviewActions.map(objectiveAction => (
                                            <EmployeeReviewFeedbackObjectivesActionItem
                                                key={objectiveAction.id}
                                                objectiveAction={objectiveAction}
                                                disabled={disabled}
                                                onEdit={() => {
                                                    setObjectiveActionDialogState({
                                                        open: true,
                                                        activeObjectiveAction: objectiveAction,
                                                    });
                                                }}
                                                onDelete={() => {
                                                    onDeleteObjective(objectiveAction.id);
                                                }}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}

                        <Button
                            fullWidth
                            onClick={() =>
                                setObjectiveActionDialogState({
                                    open: true,
                                    activeObjectiveAction: undefined,
                                })
                            }
                            disabled={disabled}
                        >
                            {t('reviews.write_feedback.create_objective_action_button')}
                        </Button>
                    </Stack>
                )}
            </StateHandler>

            {objectiveActionDialogState.open && (
                <AddIndividualObjectiveDialog
                    open={true}
                    activeObjective={objectiveActionDialogState.activeObjectiveAction?.objective}
                    disabledEmployeeSelection={true}
                    onSave={formValues => {
                        onSave(formValues, employeeReview.id, objectiveActionDialogState.activeObjectiveAction?.id);
                    }}
                    employeeId={employeeId}
                    parentObjectiveEnabled={objectiveSetting?.parentObjectiveEnabled ?? false}
                    onClose={() => {
                        resetObjectiveActionDialogState();
                    }}
                />
            )}
        </Stack>
    );
};

type EmployeeReviewFeedbackObjectivesActionItemProps = {
    objectiveAction: EmployeeReviewAction;
    disabled: boolean;
    onEdit: () => void;
    onDelete: () => void;
    parentObjective?: Objective;
};
const EmployeeReviewFeedbackObjectivesActionItem: FC<EmployeeReviewFeedbackObjectivesActionItemProps> = ({ objectiveAction, disabled, onEdit, onDelete }) => {
    const items = getMenuItems(onEdit, onDelete);
    const objective = objectiveAction.objective;
    const theme = useTheme();

    return (
        <TableRow
            onClick={disabled ? undefined : onEdit}
            sx={{
                backgroundColor: theme.palette.grey[100],
                cursor: disabled ? 'default' : 'pointer',
                '& td:first-child': {
                    borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
                },
                '& td:last-child': {
                    borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
                },
                '& td': {
                    borderBottom: 'none',
                    verticalAlign: 'top',
                },
            }}
            hover={!disabled}
        >
            <TableCell>
                <Stack direction={'row'} gap={1}>
                    <Box>
                        <ObjectivesIcon />
                    </Box>

                    <Stack direction={'column'}>
                        <Typography variant='h3'>{objective?.name}</Typography>
                        <Typography variant='body1bold'>{getLabelTranslation(objective?.category?.name)}</Typography>
                        <Typography variant='body2'>{objective?.description}</Typography>
                    </Stack>
                </Stack>
            </TableCell>

            <TableCell
                sx={{
                    justifyItems: 'center',
                }}
            >
                <EmployeeAvatar employeeAvatar={objective.createdBy} />
            </TableCell>

            <TableCell>
                <Typography variant='body2' py={1}>
                    {formatDate(objective?.dueDate, getAppConfig().DEFAULT_DATE_FORMAT)}
                </Typography>
            </TableCell>
            {!disabled && (
                <TableCell>
                    <BasicMenu items={items} />
                </TableCell>
            )}
        </TableRow>
    );
};

const getMenuItems = (onEdit: () => void, onDelete: () => void): BasicMenuItem[] => {
    const menuItems: BasicMenuItem[] = [];

    menuItems.push({
        title: i18next.t('general.edit'),
        onClick: onEdit,
    });
    menuItems.push({
        title: i18next.t('general.delete'),
        onClick: onDelete,
    });

    return menuItems;
};
