import { createDefaultLabel, isEmptyLabel } from '@/domain/label/Label.service';
import {
    ReviewBlockFormItemMutation,
    ReviewEmailMutation,
    ReviewTemplate,
    ReviewTemplateObjectivesActionItemMutation,
    ReviewTemplateObjectivesItemMutation,
    ReviewTemplatePrivateItemMutation,
    ReviewTemplateQuestionItemMutation,
    ReviewTemplateSectionItemMutation,
    ReviewTemplateSkillsItemMutation,
} from '@/domain/review-template/ReviewTemplate.model';
import { ContributorType } from '@/domain/review/Review.model';
import {
    ObjectiveActionBlockForm,
    PrivateQuestionBlockForm,
    QuestionBlockForm,
    ReviewBlockForm,
    ReviewObjectivesBlockForm,
    ReviewTemplateFormType,
    SectionBlockForm,
    SkillsBlockForm,
} from '@/page/setting/review/template/ReviewTemplateFormPage.schema';

const reviewTemplateDefaultValues: Partial<ReviewTemplateFormType> = {
    name: createDefaultLabel(),
    includePreparationStep: false,
    includeValidationStep: false,
    includePrivateManagerQuestions: false,
    managerPreparationAccess: false,
    includePrivateEmployeeQuestions: false,
    allowManagerToSkipPreparationStep: false,
    selfReview: false,
    managerPreparation: false,
    peerFeedback: false,
    upwardFeedback: false,
    reviewBlocksForm: [],
    privateManagerQuestions: [],
    privateEmployeeQuestions: [],
    managerSubject: createDefaultLabel(),
    managerBody: createDefaultLabel(),
    selfSubject: createDefaultLabel(),
    selfBody: createDefaultLabel(),
    peerSubject: createDefaultLabel(),
    peerBody: createDefaultLabel(),
    upwardSubject: createDefaultLabel(),
    upwardBody: createDefaultLabel(),
};

function mapItemsToFormValues(
    reviewTemplate: ReviewTemplate,
    selfReview: boolean,
    managerPreparation: boolean,
    peerFeedback: boolean,
    upwardFeedback: boolean,
) {
    return (reviewTemplate.items ?? [])
        .filter(
            item =>
                item?.type === 'SECTION' ||
                item?.type === 'QUESTION' ||
                item?.type === 'REVIEW_OBJECTIVES' ||
                item?.type === 'SKILLS' ||
                item?.type === 'OBJECTIVE_ACTIONS',
        )
        .map(item => {
            const availableContributors = {
                selfReview: selfReview && item.availableContributorTypes?.includes('SELF'),
                managerPreparation: managerPreparation && item.availableContributorTypes?.includes('MANAGER'),
                peerFeedback: peerFeedback && item.availableContributorTypes?.includes('PEER'),
                upwardFeedback: upwardFeedback && item.availableContributorTypes?.includes('UPWARD'),
            };
            const reviewScale = {
                reviewScaleEnabled: !!item?.rating?.id,
                reviewRatingScaleId: item?.rating?.id,
            };

            switch (item.type) {
                case 'SECTION':
                    return {
                        ...availableContributors,
                        sectionName: item.title,
                        localId: item.id.toString(),
                        order: item.order,
                        blockType: 'SECTION',
                    };
                case 'QUESTION':
                    return {
                        ...availableContributors,
                        ...reviewScale,
                        questionName: item.title,
                        instruction: item.instruction && !isEmptyLabel(item.instruction) ? item.instruction : undefined,
                        instructionEnabled: !!item.instruction && !isEmptyLabel(item.instruction),
                        required: item.required,
                        localId: item.id.toString(),
                        order: item.order,
                        blockType: 'QUESTION',
                    };
                case 'REVIEW_OBJECTIVES':
                    return {
                        ...availableContributors,
                        localId: item.id.toString(),
                        order: item.order,
                        blockType: 'REVIEW_OBJECTIVES',
                    };
                case 'OBJECTIVE_ACTIONS':
                    return {
                        ...availableContributors,
                        localId: item.id.toString(),
                        order: item.order,
                        blockType: 'OBJECTIVE_ACTIONS',
                    };
                case 'SKILLS':
                    return {
                        ...availableContributors,
                        ...reviewScale,
                        required: item.required,
                        localId: item.id.toString(),
                        order: item.order,
                        blockType: 'SKILLS',
                        allSkillsRequired: item.allSkillsRequired ?? false,
                        minSkills: item.minSkills ?? undefined,
                        maxSkills: item.maxSkills ?? undefined,
                        rangeSkillsRequired: !!item.minSkills && !!item.maxSkills,
                        instruction: item.instruction && !isEmptyLabel(item.instruction) ? item.instruction : undefined,
                        instructionEnabled: !!item.instruction && !isEmptyLabel(item.instruction),
                    };
            }
        })
        .filter(Boolean) as ReviewBlockForm[];
}

function mapPrivateQuestionsToFormValues(reviewTemplate: ReviewTemplate, blockType: 'PRIVATE_MANAGER_QUESTION' | 'PRIVATE_EMPLOYEE_QUESTION') {
    return reviewTemplate.items
        .filter(item => item.type === 'PRIVATE_MANAGER_QUESTION')
        .map(item => {
            return {
                questionName: item.title,
                instructionEnabled: !!item.instruction && !isEmptyLabel(item.instruction),
                instruction: item.instruction && !isEmptyLabel(item.instruction) ? item.instruction : undefined,
                required: item.required,
                reviewScaleEnabled: !!item?.rating?.id,
                reviewRatingScaleId: item?.rating?.id,
                localId: item.id.toString(),
                order: item.order,
                blockType: blockType,
            };
        });
}

const mapInvitationEmailsContentToFormValues = (reviewTemplate: ReviewTemplate) => {
    return {
        managerSubject: reviewTemplate.emailTemplates?.find(email => email.contributorType === 'MANAGER')?.subject ?? createDefaultLabel(),
        managerBody: reviewTemplate.emailTemplates?.find(email => email.contributorType === 'MANAGER')?.content ?? createDefaultLabel(),
        selfSubject: reviewTemplate.emailTemplates?.find(email => email.contributorType === 'SELF')?.subject ?? createDefaultLabel(),
        selfBody: reviewTemplate.emailTemplates?.find(email => email.contributorType === 'SELF')?.content ?? createDefaultLabel(),
        peerSubject: reviewTemplate.emailTemplates?.find(email => email.contributorType === 'PEER')?.subject ?? createDefaultLabel(),
        peerBody: reviewTemplate.emailTemplates?.find(email => email.contributorType === 'PEER')?.content ?? createDefaultLabel(),
        upwardSubject: reviewTemplate.emailTemplates?.find(email => email.contributorType === 'UPWARD')?.subject ?? createDefaultLabel(),
        upwardBody: reviewTemplate.emailTemplates?.find(email => email.contributorType === 'UPWARD')?.content ?? createDefaultLabel(),
    };
};

const mapToFormValues = (reviewTemplate: ReviewTemplate): ReviewTemplateFormType => {
    const { includePreparationStep, includeValidationStep, includePrivateManagerQuestions, includePrivateEmployeeQuestions } = reviewTemplate;
    const selfReview = includePreparationStep && reviewTemplate.availableContributorTypes?.includes('SELF');
    const managerPreparation = includePreparationStep && reviewTemplate.availableContributorTypes?.includes('MANAGER');
    const peerFeedback = includePreparationStep && reviewTemplate.availableContributorTypes?.includes('PEER');
    const upwardFeedback = includePreparationStep && reviewTemplate.availableContributorTypes?.includes('UPWARD');
    return {
        name: reviewTemplate.name ?? createDefaultLabel(),
        reviewType: reviewTemplate.reviewType,
        includePreparationStep: includePreparationStep,
        managerPreparationAccess: reviewTemplate.managerPreparationAccess,
        employeePreparationAccess: reviewTemplate.employeePreparationAccess,
        includeValidationStep: includeValidationStep,
        includePrivateManagerQuestions: includePrivateManagerQuestions,
        includePrivateEmployeeQuestions: includePrivateEmployeeQuestions,
        allowManagerToSkipPreparationStep: reviewTemplate.allowManagerToSkipPreparationStep,
        selfReview: selfReview,
        managerPreparation: managerPreparation,
        peerFeedback: peerFeedback,
        upwardFeedback: upwardFeedback,
        reviewBlocksForm: mapItemsToFormValues(reviewTemplate, selfReview, managerPreparation, peerFeedback, upwardFeedback),
        privateManagerQuestions: mapPrivateQuestionsToFormValues(reviewTemplate, 'PRIVATE_MANAGER_QUESTION'),
        privateEmployeeQuestions: mapPrivateQuestionsToFormValues(reviewTemplate, 'PRIVATE_EMPLOYEE_QUESTION'),
        ...mapInvitationEmailsContentToFormValues(reviewTemplate),
    };
};

export const getDefaultValues = (reviewTemplate: ReviewTemplate | undefined): Partial<ReviewTemplateFormType> => {
    if (!reviewTemplate) {
        return reviewTemplateDefaultValues;
    }
    const formFromReviewTemplate = mapToFormValues(reviewTemplate);
    return { ...reviewTemplateDefaultValues, ...formFromReviewTemplate };
};

export const getReviewAvailableContributorTypes = (reviewTemplateFormValues: ReviewTemplateFormType): ContributorType[] => {
    const availableContributorTypes: ContributorType[] = [];

    if (!reviewTemplateFormValues.includePreparationStep) {
        return [];
    }

    if (reviewTemplateFormValues.selfReview) {
        availableContributorTypes.push('SELF');
    }
    if (reviewTemplateFormValues.managerPreparation && reviewTemplateFormValues.selfReview) {
        availableContributorTypes.push('MANAGER');
    }
    if (reviewTemplateFormValues.peerFeedback) {
        availableContributorTypes.push('PEER');
    }
    if (reviewTemplateFormValues.upwardFeedback) {
        availableContributorTypes.push('UPWARD');
    }
    return availableContributorTypes;
};

export const getBlockAvailableContributorTypes = (
    item: QuestionBlockForm | SkillsBlockForm | ReviewObjectivesBlockForm | SectionBlockForm | ObjectiveActionBlockForm,
    reviewTemplateFormValues: ReviewTemplateFormType,
): ContributorType[] => {
    const availableContributorTypes: ContributorType[] = [];
    if (item.selfReview && reviewTemplateFormValues?.selfReview) {
        availableContributorTypes.push('SELF');
    }
    if (item.managerPreparation && reviewTemplateFormValues?.managerPreparation && reviewTemplateFormValues?.selfReview) {
        availableContributorTypes.push('MANAGER');
    }
    if (item.peerFeedback && reviewTemplateFormValues?.peerFeedback) {
        availableContributorTypes.push('PEER');
    }
    if (item.upwardFeedback && reviewTemplateFormValues?.upwardFeedback) {
        availableContributorTypes.push('UPWARD');
    }
    return availableContributorTypes;
};

export const getReviewBlockFormItemsMutation = (
    reviewBlockFormItems: ReviewBlockForm[],
    reviewTemplateFormValues: ReviewTemplateFormType,
): ReviewBlockFormItemMutation[] => {
    return reviewBlockFormItems.map(item => {
        switch (item.blockType) {
            case 'SECTION':
                return {
                    availableContributorTypes: getBlockAvailableContributorTypes(item, reviewTemplateFormValues),
                    type: 'SECTION',
                    title: item.sectionName,
                    order: item.order,
                } satisfies ReviewTemplateSectionItemMutation;
            case 'QUESTION':
                return {
                    type: 'QUESTION',
                    title: item.questionName,
                    instruction: item.instruction && item.instructionEnabled && !isEmptyLabel(item.instruction) ? item.instruction : undefined,
                    order: item.order,
                    availableContributorTypes: getBlockAvailableContributorTypes(item, reviewTemplateFormValues),
                    ratingId: item.reviewScaleEnabled ? (item.reviewRatingScaleId ?? undefined) : undefined,
                    required: item.required,
                } satisfies ReviewTemplateQuestionItemMutation;
            case 'REVIEW_OBJECTIVES':
                return {
                    type: 'REVIEW_OBJECTIVES',
                    order: item.order,
                    availableContributorTypes: getBlockAvailableContributorTypes(item, reviewTemplateFormValues),
                } satisfies ReviewTemplateObjectivesItemMutation;
            case 'OBJECTIVE_ACTIONS':
                return {
                    type: 'OBJECTIVE_ACTIONS',
                    order: item.order,
                    availableContributorTypes: getBlockAvailableContributorTypes(item, reviewTemplateFormValues),
                } satisfies ReviewTemplateObjectivesActionItemMutation;
            case 'SKILLS':
                return {
                    type: 'SKILLS',
                    order: item.order,
                    instruction: item.instruction && item.instructionEnabled && !isEmptyLabel(item.instruction) ? item.instruction : undefined,
                    availableContributorTypes: getBlockAvailableContributorTypes(item, reviewTemplateFormValues),
                    allSkillsRequired: item.allSkillsRequired,
                    minSkills: item.minSkills ?? undefined,
                    maxSkills: item.maxSkills ?? undefined,
                    rangeSkillsRequired: item.rangeSkillsRequired,
                } satisfies ReviewTemplateSkillsItemMutation;
        }
    });
};

export const getPrivateQuestionsMutation = (
    privateQuestions: PrivateQuestionBlockForm[],
    privateQuestionType: 'PRIVATE_MANAGER_QUESTION' | 'PRIVATE_EMPLOYEE_QUESTION',
    includePrivateQuestions: boolean,
): ReviewTemplatePrivateItemMutation[] => {
    if (!includePrivateQuestions) {
        return [];
    }

    return privateQuestions.map<ReviewTemplatePrivateItemMutation>(item => {
        return {
            title: item.questionName,
            instruction: item.instruction && item.instructionEnabled && !isEmptyLabel(item.instruction) ? item.instruction : undefined,
            order: item.order,
            ratingId: item.reviewScaleEnabled ? item.reviewRatingScaleId : undefined,
            required: item.required,
            type: privateQuestionType,
        };
    });
};

export const getInvitationEmailsContentMutation = (reviewTemplateFormValues: ReviewTemplateFormType): ReviewEmailMutation[] => {
    const emailMutations: ReviewEmailMutation[] = [];

    emailMutations.push({
        subject: reviewTemplateFormValues.managerSubject,
        content: reviewTemplateFormValues.managerBody,
        contributorType: 'MANAGER',
    });

    if (reviewTemplateFormValues.selfSubject && reviewTemplateFormValues.selfReview && reviewTemplateFormValues.includePreparationStep) {
        emailMutations.push({
            subject: reviewTemplateFormValues.selfSubject ?? createDefaultLabel(),
            content: reviewTemplateFormValues.selfBody ?? createDefaultLabel(),
            contributorType: 'SELF',
        });
    }

    if (reviewTemplateFormValues.peerSubject && reviewTemplateFormValues.peerFeedback && reviewTemplateFormValues.includePreparationStep) {
        emailMutations.push({
            subject: reviewTemplateFormValues.peerSubject ?? createDefaultLabel(),
            content: reviewTemplateFormValues.peerBody ?? createDefaultLabel(),
            contributorType: 'PEER',
        });
    }

    if (reviewTemplateFormValues.upwardSubject && reviewTemplateFormValues.upwardFeedback && reviewTemplateFormValues.includePreparationStep) {
        emailMutations.push({
            subject: reviewTemplateFormValues.upwardSubject ?? createDefaultLabel(),
            content: reviewTemplateFormValues.upwardBody ?? createDefaultLabel(),
            contributorType: 'UPWARD',
        });
    }

    return emailMutations;
};
