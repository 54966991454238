import { mapRatingScale } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackForm.util';
import {
    EmployeeReviewFeedbackFormType,
    EmployeeReviewFeedbackSummarySkillFormType,
    EmployeeReviewSummaryFeedbackItemFormType,
    EmployeeReviewSummaryFeedbackObjectiveFormType,
    EmployeeReviewSummaryFeedbackQuestionFormType,
    EmployeeReviewSummaryFeedbackSectionFormType,
    EmployeeReviewSummaryFeedbackSkillQuestionFormType,
    EmployeeReviewSummaryFeedbackSkillsFormType,
} from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm.schema';
import { EmployeeReviewManagerSummary, EmployeeReviewSummaryItem } from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import { getLabelTranslation } from '@/utils/language.util';
import {
    isReviewTemplateItemObjectives,
    isReviewTemplateItemObjectivesAction,
    isReviewTemplateItemPrivateEmployeeQuestion,
    isReviewTemplateItemPrivateManagerQuestion,
    isReviewTemplateItemQuestion,
    isReviewTemplateItemSection,
    isReviewTemplateItemSkill,
    isReviewTemplateItemSkills,
} from '@/domain/review-template/ReviewTemplate.service';
import { ReviewFeedback, ReviewItem } from '@/domain/review/Review.model';
import { createDefaultLabel } from '@/domain/label/Label.service';
import { EmployeeReviewFeedbackObjectivesActionFormType } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackFormPage.schema';

const mapReviewFeedback = (reviewFeedback: ReviewFeedback): Exclude<EmployeeReviewFeedbackFormType, null | undefined> => {
    return {
        score: reviewFeedback.score,
        comment: reviewFeedback.comment,
        reviewer: {
            firstName: reviewFeedback.reviewer?.firstName ?? '',
            lastName: reviewFeedback.reviewer?.lastName ?? '',
            displayName: reviewFeedback.reviewer?.displayName ?? '',
            avatarImageUrl: reviewFeedback.reviewer?.avatarImageUrl ?? '',
            id: reviewFeedback.reviewer?.id ?? 0,
            email: reviewFeedback.reviewer?.email ?? '',
        },
    };
};

export const mapEmployeeReviewSummaryFeedbackItem = (
    reviewItem: ReviewItem,
    employeeReviewManagerSummary: EmployeeReviewManagerSummary,
): EmployeeReviewSummaryFeedbackItemFormType | undefined => {
    switch (reviewItem.type) {
        case 'SECTION':
            return mapEmployeeReviewSummaryFeedbackSectionForm(reviewItem);
        case 'SKILL':
            return mapEmployeeReviewSummaryFeedbackSkillQuestionForm(reviewItem, employeeReviewManagerSummary);
        case 'REVIEW_OBJECTIVES':
            return mapEmployeeReviewSummaryFeedbackObjectiveForm(reviewItem);
        case 'OBJECTIVE_ACTIONS':
            return mapEmployeeReviewSummaryObjectivesActionForm(reviewItem);
        case 'SKILLS':
            return mapEmployeeReviewSummaryFeedbackSkillsForm(reviewItem, employeeReviewManagerSummary);
        case 'QUESTION':
        case 'PRIVATE_EMPLOYEE_QUESTION':
        case 'PRIVATE_MANAGER_QUESTION':
            return mapEmployeeReviewSummaryFeedbackQuestionForm(reviewItem, employeeReviewManagerSummary);
        default:
            throw new Error(`Unsupported item type: ${reviewItem.type}`);
    }
};

const mapEmployeeReviewSummaryFeedbackSectionForm = (item: ReviewItem): EmployeeReviewSummaryFeedbackSectionFormType => {
    if (!isReviewTemplateItemSection(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        id: item.id,
        order: item.order,
        title: item?.title ?? createDefaultLabel(),
        type: 'SECTION',
    };
};

const getSummaryItem = (itemId: number, employeeReviewManagerSummary: EmployeeReviewManagerSummary): EmployeeReviewSummaryItem | undefined => {
    return employeeReviewManagerSummary.items.find(item => item.reviewItemId === itemId);
};

const mapEmployeeReviewSummaryFeedbackSkillQuestionForm = (
    item: ReviewItem,
    employeeReviewManagerSummary: EmployeeReviewManagerSummary,
): EmployeeReviewSummaryFeedbackSkillQuestionFormType | undefined => {
    if (!isReviewTemplateItemSkill(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    const summaryItem = getSummaryItem(item.id, employeeReviewManagerSummary);

    if (!summaryItem) {
        return;
    }

    return {
        required: item.required,
        id: item.id,
        description: getLabelTranslation(item?.skill?.description),
        order: item.order,
        rating: item.rating ? mapRatingScale(item.rating) : undefined,
        selfFeedbackQuestion: summaryItem.employeeReviewFeedbackSkillQuestionSummary?.self
            ? mapReviewFeedback(summaryItem.employeeReviewFeedbackSkillQuestionSummary?.self)
            : undefined,
        managerFeedbackQuestion: (summaryItem.employeeReviewFeedbackSkillQuestionSummary?.managers ?? []).map(mapReviewFeedback),
        upwardFeedbackQuestion: (summaryItem.employeeReviewFeedbackSkillQuestionSummary?.upwards ?? []).map(mapReviewFeedback),
        peerFeedbackQuestion: (summaryItem.employeeReviewFeedbackSkillQuestionSummary?.peers ?? []).map(mapReviewFeedback),
        score: summaryItem.employeeReviewFeedbackSkillQuestionSummary?.summaryScore,
        comment: summaryItem.employeeReviewFeedbackSkillQuestionSummary?.summaryComment ?? '',
        type: 'SKILL',
        title: getLabelTranslation(item?.skill?.name),
    };
};

const mapEmployeeReviewSummaryFeedbackObjectiveForm = (item: ReviewItem): EmployeeReviewSummaryFeedbackObjectiveFormType => {
    if (!isReviewTemplateItemObjectives(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        id: item.id,
        type: 'REVIEW_OBJECTIVES',
        order: item.order,
    };
};

const mapEmployeeReviewSummaryObjectivesActionForm = (item: ReviewItem): EmployeeReviewFeedbackObjectivesActionFormType => {
    if (!isReviewTemplateItemObjectivesAction(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    return {
        id: item.id,
        type: 'OBJECTIVE_ACTIONS',
        order: item.order,
    };
};

const mapEmployeeReviewSummaryFeedbackSkillsForm = (
    item: ReviewItem,
    employeeReviewManagerSummary: EmployeeReviewManagerSummary,
): EmployeeReviewSummaryFeedbackSkillsFormType | undefined => {
    if (!isReviewTemplateItemSkills(item.type)) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    const summaryItem = getSummaryItem(item.id, employeeReviewManagerSummary);

    if (!summaryItem) {
        return undefined;
    }

    return {
        id: item.id,
        type: 'SKILLS',
        rating: item.rating ? mapRatingScale(item.rating) : undefined,
        minSkills: item?.minSkills ?? undefined,
        maxSkills: item?.maxSkills ?? undefined,
        allSkillsRequired: item?.allSkillsRequired ?? false,
        instruction: item?.instruction ?? createDefaultLabel(),
        order: item.order,
        skills: (summaryItem.employeeReviewFeedbackSkillsSummary?.skills ?? [])
            .sort((a, b) => a.skill.category.order - b.skill.category.order)
            .map(
                skill =>
                    ({
                        score: skill.summaryScore ?? undefined,
                        comment: skill.summaryComment ?? '',
                        skill: {
                            id: skill.skill.id,
                            name: getLabelTranslation(skill.skill.name),
                            description: getLabelTranslation(skill.skill.description),
                            levels: skill.skill.category.levels.map(level => ({
                                id: level.id,
                                description: getLabelTranslation(level.description) ?? '',
                                score: level.score,
                                commentRequired: level.commentRequired,
                                name: getLabelTranslation(level.name),
                            })),
                        },
                        selfFeedbackQuestion: skill.self ? mapReviewFeedback(skill.self) : undefined,
                        managerFeedbackQuestion: skill.managers?.map(mapReviewFeedback) ?? [],
                        upwardFeedbackQuestion: skill.upwards?.map(mapReviewFeedback) ?? [],
                        peerFeedbackQuestion: skill.peers?.map(mapReviewFeedback) ?? [],
                        category: {
                            id: skill.skill.category.id,
                            name: getLabelTranslation(skill.skill.category.name),
                        },
                    }) satisfies EmployeeReviewFeedbackSummarySkillFormType,
            ),
    };
};

const mapEmployeeReviewSummaryFeedbackQuestionForm = (
    item: ReviewItem,
    employeeReviewManagerSummary: EmployeeReviewManagerSummary,
): EmployeeReviewSummaryFeedbackQuestionFormType | undefined => {
    if (
        !isReviewTemplateItemQuestion(item.type) &&
        !isReviewTemplateItemPrivateEmployeeQuestion(item.type) &&
        !isReviewTemplateItemPrivateManagerQuestion(item.type)
    ) {
        throw new Error(`Unsupported item type: ${item.type}`);
    }

    const summaryItem = getSummaryItem(item.id, employeeReviewManagerSummary);

    if (!summaryItem) {
        return;
    }

    return {
        rating: item?.rating ? mapRatingScale(item.rating) : undefined,
        /* The response to the question is mandatory in the manager-summary only if both the 'required' checkbox and the 'manager' checkbox are checked in the template.
           This ensures that questions intended only for the employee are not mandatory for the manager, reducing unnecessary input for managers. */
        required:
            item.required &&
            (item.contributorTypes.includes('MANAGER') || item.type === 'PRIVATE_MANAGER_QUESTION' || item.type === 'PRIVATE_EMPLOYEE_QUESTION'),
        order: item.order,
        id: item.id,
        // todo : complete the description, the backend should return this value
        description: '',
        score: summaryItem.employeeReviewFeedbackQuestionSummary?.summaryScore ?? undefined,
        comment: summaryItem.employeeReviewFeedbackQuestionSummary?.summaryComment ?? '',
        title: item?.title ?? createDefaultLabel(),
        instruction: item?.instruction ?? createDefaultLabel(),
        selfFeedbackQuestion: summaryItem.employeeReviewFeedbackQuestionSummary?.self
            ? mapReviewFeedback(summaryItem.employeeReviewFeedbackQuestionSummary?.self)
            : undefined,
        managerFeedbackQuestion: (summaryItem.employeeReviewFeedbackQuestionSummary?.managers ?? []).map(mapReviewFeedback),
        upwardFeedbackQuestion: (summaryItem.employeeReviewFeedbackQuestionSummary?.upwards ?? []).map(mapReviewFeedback),
        peerFeedbackQuestion: (summaryItem.employeeReviewFeedbackQuestionSummary?.peers ?? []).map(mapReviewFeedback),
        type: item.type,
    };
};
